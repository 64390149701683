import React, { useState, useRef } from "react";
import { navigate } from "gatsby";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import useStickyState from "../hooks/useStickyState";

import { IoMdClose } from "react-icons/io";
import UK_flag from "../../static/img/flag-uk.svg";

export default function RegionModal() {
  // Setting state for opening/closing the Modal.
  // Currently set to false, delete this comment and set to true when ready to make live!
  const [modal, setModal] = useState(false);

  // Title ref so focus isn't on the first button.
  const titleRef = useRef();

  // Declaring local storage state if client chooses to stay on current region (using a custom hook).
  const [stayOnCurrentRegion, setStayOnCurrentRegion] = useStickyState(
    false,
    "stayOnCurrentRegion"
  );

  function handleClick() {
    // Declare local storage item to stay on current region.
    localStorage.setItem("stayOnCurrentRegion", true);

    // Get local storage item and pass it to stayOnCurrentRegion useState.
    const ls = localStorage.getItem("stayOnCurrentRegion");
    setStayOnCurrentRegion(ls);

    // Close modal.
    setModal(false);
  }

  return (
    <>
      {/* Retrieving local storage converts boolean to string. */}
      {modal && stayOnCurrentRegion !== "true" && (
        <DialogOverlay
          className="region-modal-overlay"
          onDismiss={handleClick}
          initialFocusRef={titleRef}
        >
          <DialogContent className="region-modal-content">
            <img src={UK_flag} className="region-photo" />
            <h1 className="region-title">Welcome to Upper Canada UK</h1>
            <p className="region-description">Choose your region below.</p>
            <button className="button region-button" onClick={handleClick}>
              Continue on UK
            </button>
            <div className="other-regions">
              <button
                className="button is-white region-button region-sub-button"
                onClick={() => navigate("https://uppercanadasoap.com")}
              >
                US
              </button>
              <button
                className="button is-white region-button region-sub-button"
                onClick={() => navigate("https://uppercanadaeu.com")}
              >
                EU
              </button>
            </div>

            {/* Close icon */}
            <button
              className="region-modal-close"
              onClick={handleClick}
              ref={titleRef}
            >
              <IoMdClose />
            </button>
          </DialogContent>
        </DialogOverlay>
      )}
    </>
  );
}
